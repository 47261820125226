/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 14:21:53
 * @LastEditTime: 2021-12-29 14:21:54
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  autosize: { minRows: 2, maxRows: 6 },
  resize: "none",
  maxlength: 500,
  openShowLimit: true
}