/*
 * @Description:
 * @Date: 2021-09-15 15:00:30
 * @LastEditTime: 2021-10-15 11:36:45
 * @FilePath: \web-template\src\i18n\lang\en.js
 * @Author: Devin
 */
export default {
  public: {
    add: 'Add',
    delete: 'Delete',
    look: 'View',
    editor: 'Edit',
    cancel: 'Cancel',
    confirm: 'Confirm',
    title: 'Title',
    save: 'Save',
    uploaded: 'Uploaded',
    yidongdao: 'Move',
    right: 'Confirm',
    download: 'Download',
    Potal: 'Potal',
    Home: 'Home',
    Internationalization: 'i18n',
    Documentation: 'Documentation',
    GeneratorCode: 'Generator Code',
    Components: 'Components',
    IconBtn: 'Icon Button',
    Guide: 'Guide',
    RichText: 'Rich Text',
    Chart: 'Chart',
    ErrorPages: 'Error Pages',
    Page401: '401',
    Page404: '404',
    Input: 'Input',
    pleaseInput: 'Please input',
    pleaseSelect: 'Please select',
    Select: 'Basic Select',
    VirtualRollingSelect: 'Virtual Rolling Select',
    DateSelect: 'Date Select',
    Button: 'Button',
    MessageBox: 'Message Box',
    dialogTitle: 'Hint',
    dialogText: 'Whether or not to continue?',
    Table: 'Table',
    loading: 'Loading...',
    operation: 'Operation',
    Switch: 'Switch',
    form: 'Form',
    Dialog: 'Dialog'
  }
};
