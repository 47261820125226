/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 11:15:41
 * @LastEditTime: 2021-12-29 11:17:19
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  size: 'small',
  clearable:true,
  openShowLimit:true,
}
