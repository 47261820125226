/*
 * @Description:
 * @Date: 2021-10-08 17:29:54
 * @LastEditTime: 2021-10-08 17:30:37
 * @FilePath: \mds\src\components\tinymce\font_formats.js
 * @Author: Devin
 */
export const font_formats =
  '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;';
export const fontsize_formats = '12px 14px 16px 18px 24px 36px 48px 56px 72px';
