/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 11:19:02
 * @LastEditTime: 2021-12-29 11:21:23
 * @LastEditors: Devin
 * @Reference: 
 */
export const configConfirm = {
  title: '标题',
  message: '是否继续？',
  confirmButtonText: '确认',
  cancelButtonText: '取消',
  type: 'warning',
  center: false,
  confirmButtonClass: 'confirmButtonClass',
  cancelButtonClass: 'cancelButtonClass',
  closeOnClickModal: false
}

export const configAlert = {
  title: '标题',
  message: '是否继续？',
  confirmButtonText: '确认',
  showClose: true,
  type: 'warning',
  center: false,
  confirmButtonClass: 'confirmButtonClass'
}
