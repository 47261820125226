/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 10:54:10
 * @LastEditTime: 2021-12-29 10:55:48
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  valueFormat: 'yyyy-MM-dd HH:mm:ss',
  format: 'yyyy-MM-dd',
  size: 'small',
  type: 'date',
  prefixIcon: 'iconfont icon-riqi',
  disabledTimeRange: ''
}