/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 10:48:44
 * @LastEditTime: 2021-12-29 10:48:44
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  size: 'small',
  classArray: [],
  textAdd: '添加'
}