/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 10:56:18
 * @LastEditTime: 2021-12-29 10:56:18
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  title: '',
  width: '50%',
  visible: false,
  closeOnClickModal: false,
  showFooter: false,
  cancelBtnText: '',
  confirmBtnText: '',
  dialogDrag: true,
  showCancel: true,
  showConfirm: true
}