/*
 * @Description:
 * @Date: 2021-09-15 15:00:14
 * @LastEditTime: 2021-12-29 16:38:50
 * @FilePath: \web-template\src\i18n\lang\zh.js
 * @Author: Devin
 */
export default {
  public: {
    add: '添加',
    delete: '删除',
    look: '查看',
    editor: '编辑',
    save: '保存',
    uploaded: '上传',
    yidongdao: '移动',
    cancel: '取消',
    confirm: '确定',
    title: '标题',
    right: '确认',
    download: '下载',
    Potal: 'Potal',
    Home: '首页',
    Internationalization: '国际化',
    Documentation: '文档',
    GeneratorCode: '代码生成',
    Components: '组件',
    IconBtn: '图标按钮',
    Guide: '引导页',
    RichText: '富文本',
    Chart: '图表',
    ErrorPages: '错误页面',
    Page401: '401',
    Page404: '404',
    Input: '输入框',
    pleaseInput: '请输入',
    pleaseSelect: '请选择',
    Select: '基础选择器',
    VirtualRollingSelect: '虚拟滚动选择器',
    DateSelect: '日期选择器',
    Button: '按钮',
    MessageBox: '二次弹框',
    dialogTitle: '提示',
    dialogText: '是否继续？',
    Table: '表格',
    loading: '加载中...',
    operation: '操作',
    Switch: '开关',
    pieCharts: '饼状图',
    barCharts: '柱状图',
    lineCharts: '折线图',
    form: '表单',
    requiredInput: '请输入',
    requiredSelect: '请选择',
    Dialog: '对话框',
    uploadImage: '上传裁剪图片',
    resize:'拖动'
  },
  echarts: {
    dataView: '数据视图',
    zoomView: '缩放视图',
    backView: '缩放视图还原',
    savePic: '保存为图片',
    changeLine: '切换为折线图',
    changeBar: '切换为柱状图',
    changeStack: '切换堆叠',
    changeTiled: '切换平铺'
  }
};