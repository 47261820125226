/*
 * @Description:
 * @Date: 2021-11-02 15:05:48
 * @LastEditTime: 2021-12-29 10:40:49
 * @FilePath: \cc-ecoa\src\utils\cc-components-config\icon-button.js
 * @Author: Devin
 */
export const iconClass = {
  editor: 'icon-bianji',
  delete: 'icon-shanchu',
  look: 'icon-chakan',
  confirm: 'icon-queding',
  download: 'icon-xiazai',
  uploading: 'icon-shangchuan',
  cancel: 'icon-guanbi',
  move: 'icon-yidongdao'
};

export const titleTexts = {
  editor: '编辑',
  delete: '删除',
  look: '查看',
  confirm: '确认',
  download: '下载',
  uploading: '上传',
  cancel: '取消',
  move: '移动'
};
