import { loginReq, logoutReq, getInfoReq } from '@/api/user'
import { setToken, removeToken, setRoles } from '@/utils/auth';

import router, { asyncRouterMap } from '@/router'

//当路由被删除时，所有的别名和子路由也会被同时删除
//https://router.vuejs.org/zh/guide/advanced/dynamic-routing.html#%E5%88%A0%E9%99%A4%E8%B7%AF%E7%94%B1
const resetRouter = () => {
  let asyncRouterNameArr = asyncRouterMap.map((mItem) => mItem.name)
  asyncRouterNameArr.forEach((name) => {
    if (router.hasRoute(name)) {
      router.removeRoute(name)
    }
  })
}

const getDefaultState = () => {
  return {
    //token: getToken(),
    username: '',
    areaName: '',
    avatar: '',
    roles: []
  }
}

const state = getDefaultState()

const mutations = {
  M_username: (state, username) => {
    state.username = username
  },
  M_roles: (state, roles) => {
    state.roles = roles
  },
  M_areaName: (state, areaName) => {
    state.areaName = areaName
  }

  // SET_AVATAR: (state, avatar) => {
  //   state.avatar = avatar
  // }
}

const actions = {
  // user login
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      loginReq(data)
        .then((res) => {
          console.log('登录成功')
          console.log(res)
          if (res.code === 200) {
            commit('SET_Token', res.data?.token)
            setToken(res.data?.token)
            resolve()
          } else {
            reject(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfoReq()
        .then((response) => {
          const { data } = response
          console.log(data)
          commit('M_roles', data.roles)
          commit('M_username', data.userName)
          commit('M_areaName', data.areaName)
          localStorage.setItem("areaName", data.areaName)
          setRoles(data.roles)
          console.log(state.roles)
          localStorage.removeItem("Developer")
          if (data.roles.indexOf('root') > -1) {
            localStorage.setItem("Developer", "Developer")
            asyncRouterMap.push({
                path: '/',
                redirect: '/businessOverview/index',
                hidden: true
            })
          } else if (data.roles.indexOf('branch') > -1) {
            asyncRouterMap.push({
              path: '/',
              redirect: '/marketingData/index',
              hidden: true
            })
          } else if (data.roles.indexOf('front-desk') > -1) {
            asyncRouterMap.push({
              path: '/',
              redirect: '/placeAnOrder/index',
              hidden: true
            })
          }
          resolve({roles: data.roles})
        })
        .catch((error) => {
          reject(error)
        })

    })
  },
  // user logout
  logout({ dispatch }) {
    return new Promise((resolve, reject) => {
      logoutReq()
        .then(() => {
          dispatch('resetState')
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resetState({ commit, dispatch }) {
    return new Promise((resolve) => {
      console.log('==========')
      commit('M_username', '')
      commit('M_areaName', '')
      commit('M_roles', [])
      removeToken() // must remove  token  first
      // resetRouter() // reset the router
      commit('permission/M_isGetUserInfo', false, { root: true })

      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true })
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
