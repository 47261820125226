/*
 * @Description:
 * @Date: 2021-09-18 14:33:29
 * @LastEditTime: 2021-09-18 14:41:25
 * @FilePath: \mds\src\utils\axios\requestError.js
 * @Author: Devin
 */
function requestError(error) {
  console.log(error);
}
export default requestError