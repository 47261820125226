// import request from '@/utils/axiosReq'
import request from '@/utils/axios/index';

export function loginReq(data) {
  return request({
    url: '/api/login',
    data,
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getInfoReq() {
  return request({
    url: '/api/getUserInfo',
    bfLoading: false,
    method: 'get',
    isAlertErrorMsg: false
  })
}

export function logoutReq() {
  return request({
    url: '/api/tUser/logout',
    method: 'post'
  })
}



export function saveOrUpdateSysUser(data) {
  return request({
    url: '/api/sysUser/saveOrUpdate',
    data,
    method: 'post',
    bfLoading: true,
    isParams: false,
    isAlertErrorMsg: true
  })
}

export function listSysUser(data) {
  return request({
    url: '/api/sysUser/list',
    bfLoading: true,
    data: data,
    isParams: true,
    method: 'get',
    isAlertErrorMsg: true
  })
}

export function delSysUser(id) {
  return request({
    url: '/api/sysUser/del/'+id,
    method: 'post',
    isAlertErrorMsg: true
  })
}

export function getSysUser(id) {
  return request({
    url: '/api/sysUser/get?id='+id,
    method: 'get',
    isAlertErrorMsg: true
  })
}
export function getArea() {
  return request({
    url: '/api/sysUser/getArea',
    method: 'get',
    isAlertErrorMsg: true
  })
}

export function getCusInfo(data) {
  return request({
    url: '/api/customer/getCusInfo',
    method: 'get',
    data: data,
    bfLoading: true,
    isParams: true,
    isAlertErrorMsg: true
  })
}

export function getOperLog(data) {
  return request({
    url: '/api/customer/getOperLog',
    method: 'get',
    data: data,
    bfLoading: true,
    isParams: true,
    isAlertErrorMsg: true
  })
}
export function editUserBaseInfo(data) {
  return request({
    url: '/api/customer/editUserBaseInfo',
    method: 'get',
    data: data,
    bfLoading: true,
    isParams: true,
    isAlertErrorMsg: true
  })
}
export function editDeposit(data) {
  return request({
    url: '/api/customer/editDeposit',
    method: 'get',
    data: data,
    bfLoading: true,
    isParams: true,
    isAlertErrorMsg: true
  })
}
export function editBucketNumber(data) {
  return request({
    url: '/api/customer/editBucketNumber',
    method: 'get',
    data: data,
    bfLoading: true,
    isParams: true,
    isAlertErrorMsg: true
  })
}
export function editWaterTicketNumber(data) {
  return request({
    url: '/api/customer/editWaterTicketNumber',
    method: 'get',
    data: data,
    bfLoading: true,
    isParams: true,
    isAlertErrorMsg: true
  })
}