/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 13:59:53
 * @LastEditTime: 2021-12-29 14:00:57
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  stickyTop: 0,
  scrollTop: 0,
  zIndex: 1,
  className: '',
  right: 20,
}