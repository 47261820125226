/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 11:49:30
 * @LastEditTime: 2021-12-29 11:49:31
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  placeholder: '请选择',
  optionKeys: { value: 'id', label: 'name' },
  styles: 'width:260px',
  size: 'small',
  listField: 'data',
  disableValues: [],
  showValues: [],
  isLoading: false,
  clearable: true,
  url: ''
}