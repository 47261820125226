/*
 * @Description:
 * @Date: 2021-09-18 14:33:29
 * @LastEditTime: 2021-09-18 14:42:21
 * @FilePath: \mds\src\utils\axios\responseBusinessError.js
 * @Author: Devin
 */
import { MessageBox, Message } from 'element-ui';
import router from '@/router'
import { removeToken } from '@/utils/auth';
function responseBusinessError(responseData) {
  if (responseData.code === 50008 || responseData.code === 50012 || responseData.code === 50014 || responseData.code === 201) {
    Message.error(responseData.msg)
    removeToken() // must remove  token  first
    store.dispatch('user/resetState').then(() => {
      //direct return
      console.log('23234')
      router.push({ path: '/login' })
    })
    // MessageBox.confirm('您已注销，您可以取消停留在此页面，或重新登录', '确认注销', {
    //   confirmButtonText: '重新登录',
    //   cancelButtonText: '取消',
    //   type: 'warning'
    // }).then(() => {
    //   console.log(responseData);
    // });
  }
  Message.error(responseData.message)
  console.log(responseData.message)
  return Promise.reject(new Error(responseData.message || 'Error'));
}
export default responseBusinessError