/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 10:51:21
 * @LastEditTime: 2021-12-29 10:51:21
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  valueFormat: 'yyyy-MM-dd HH:mm:ss',
  format: 'yyyy-MM-dd',
  size: 'small',
  prefixIcon: 'iconfont icon-riqi',
  startPlaceholder: '开始日期',
  endPlaceholder: '结束日期',
  rangeSeparator: '-',
  defaultTime: ['00:00:00', '00:00:00'],
  popperClass: '',
  hiddenHeader: true
}