/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 11:01:44
 * @LastEditTime: 2021-12-29 11:01:44
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  size: 'small',
  labelPosition: 'top',
  gutter: 20,
  showResetBtn: false,
  itemWidth: '',
  showCancelButton: true,
  showSubmitButton: true,
  submitLoading: true,
  submitBtnText: '确认',
  cancelBtnText: '取消',
  resetBtnText: '重置',
  forms: {
    required: true
  }
}