/*
 * @Description:
 * @Date: 2021-09-13 17:11:23
 * @LastEditTime: 2021-12-31 16:43:11
 * @FilePath: \element-ui_table_form\src\router\index.js
 * @Author: Devin
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Layout from '@/layout';
// import componentsRoutes from './components';
Vue.use(VueRouter);
export const constantRouterMap = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    hidden: true,
    //添加权限访问，表示只有登录之后才能进行该操作
    meta: {
      requireAuth: true
    }
  },
  // {
  //   path: '/',
  //   redirect: '/home/index',
  //   hidden: true
  // },
  // {
  //   path: '/home',
  //   name: 'MDS',
  //   component: Layout,
  //   redirect: '/home/index',
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'Home',
  //       component: () => import('@/views/home/index.vue'),
  //       meta: {
  //         title: 'Home',
  //         icon: 'icon-shouye'
  //       }
  //     }
  //   ]
  // }
];

export const asyncRouterMap = [
  // {
  //   path: '/miniapp',
  //   name: 'miniapp',
  //   component: Layout,
  //   redirect: '/miniapp/setting',
  //   meta: {
  //     title: '小程序',
  //     icon: 'icon-echarts',
  //     roles: ['root']
  //   },
  //   children: [
  //     {
  //       path: 'setting',
  //       name: 'setting',
  //       component: () => import('@/views/miniapp/setting.vue'),
  //       meta: {
  //         title: '小程序设置',
  //         icon: 'icon-shouye',
  //         roles: ['root']
  //       }
  //     },
  //     {
  //       path: 'paySetting',
  //       name: 'paySetting',
  //       component: () => import('@/views/miniapp/paySetting.vue'),
  //       meta: {
  //         title: '支付设置',
  //         icon: 'icon-shouye',
  //         roles: ['root']
  //       }
  //     },
  //     {
  //       path: 'accountSelect',
  //       name: 'accountSelect',
  //       component: () => import('@/views/miniapp/account/accountSelect.vue'),
  //       meta: {
  //         title: '账号管理',
  //         icon: 'icon-shouye',
  //         roles: ['root']
  //       }
  //     },
  //     {
  //       path: 'accountList',
  //       name: 'accountList',
  //       hidden: true,
  //       component: () => import('@/views/miniapp/account/accountList.vue'),
  //       meta: {
  //         title: '账号管理',
  //         icon: 'icon-shouye',
  //         roles: ['root']
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/store',
  //   name: 'store',
  //   meta: {
  //     title: '门店设置',
  //     icon: 'icon-echarts',
  //     roles: ['root']
  //   },
  //   component: Layout,
  //   redirect: '/store/index',
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'index',
  //       component: () => import('@/views/store/index.vue'),
  //       meta: {
  //         title: '门店设置',
  //         icon: 'icon-shouye',
  //         roles: ['root']
  //       }
  //     },
  //     {
  //       path: 'add',
  //       name: 'add',
  //       hidden: true,
  //       component: () => import('@/views/store/add.vue'),
  //       meta: {
  //         title: '门店设置',
  //         icon: 'icon-shouye',
  //         roles: ['root']
  //       }
  //     }
  //   ]
  // },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '首页',
      icon: 'icon-echarts',
      roles: ['root', 'branch', 'front-desk']
    },
    component: Layout,
    redirect: '/index/index',
    children: [
      {
        path: 'index',
        name: 'index',
        hidden: true,
        component: () => import('@/views/index/index.vue'),
        meta: {
          title: '营销活动',
          icon: 'icon-shouye',
          roles: ['root', 'branch', 'front-desk']
        }
      }
    ]
  },
  {
    path: '/businessOverview',
    name: 'businessOverview',
    meta: {
      title: '经营概况',
      icon: 'icon-echarts',
      roles: ['root']
    },
    component: Layout,
    redirect: '/businessOverview/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/businessOverview/index.vue'),
        meta: {
          title: '经营概况',
          icon: 'icon-shouye',
          roles: ['root']
        }
      },
      {
        path: 'store',
        name: 'businessOverviewStore',
        hidden: true,
        component: () => import('@/views/businessOverview/store.vue'),
        meta: {
          title: '经营概况',
          icon: 'icon-shouye',
          roles: ['root']
        }
      }
    ]
  },
  {
    path: '/salesReport',
    name: 'salesReport',
    meta: {
      title: '销售报表',
      icon: 'icon-echarts',
      roles: ['root']
    },
    component: Layout,
    redirect: '/salesReport/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/salesReport/index.vue'),
        meta: {
          title: '销售报表',
          icon: 'icon-shouye',
          roles: ['root']
        }
      },
      {
        path: 'list',
        name: 'salesReportList',
        hidden: true,
        component: () => import('@/views/salesReport/list.vue'),
        meta: {
          title: '销售报表',
          icon: 'icon-shouye',
          roles: ['root']
        }
      },
      {
        path: 'detail',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/salesReport/detail.vue'),
        meta: {
          title: '销售报表',
          icon: 'icon-shouye',
          roles: ['root']
        }
      }
    ]
  },
  {
    path: '/monthlyStatement',
    name: 'monthlyStatement',
    meta: {
      title: '月结签单',
      icon: 'icon-echarts',
      roles: ['root']
    },
    component: Layout,
    redirect: '/monthlyStatement/index',
    children: [
      {
        path: 'index',
        name: 'monthlyStatement',
        component: () => import('@/views/monthlyStatement/index.vue'),
        meta: {
          title: '月结签单',
          icon: 'icon-shouye',
          roles: ['root']
        }
      },
      {
        path: 'store',
        name: 'monthlyStatementStore',
        hidden: true,
        component: () => import('@/views/monthlyStatement/store.vue'),
        meta: {
          title: '月结签单用户',
          icon: 'icon-shouye',
          roles: ['root']
        }
      },
      {
        path: 'storeUser',
        name: 'storeUser',
        hidden: true,
        component: () => import('@/views/monthlyStatement/storeUser.vue'),
        meta: {
          title: '月结签单用户',
          icon: 'icon-shouye',
          roles: ['root']
        }
      }
    ]
  },
  {
    path: '/delivery',
    name: 'delivery',
    meta: {
      title: '配送费用',
      icon: 'icon-echarts',
      roles: ['root']
    },
    component: Layout,
    redirect: '/delivery/index',
    children: [
      {
        path: 'index',
        name: 'delivery',
        component: () => import('@/views/delivery/index.vue'),
        meta: {
          title: '配送费用',
          icon: 'icon-shouye',
          roles: ['root']
        }
      },
      {
        path: 'store',
        name: 'deliveryStore',
        hidden: true,
        component: () => import('@/views/delivery/store.vue'),
        meta: {
          title: '配送费用',
          icon: 'icon-shouye',
          roles: ['root']
        }
      },
      {
        path: 'storeUser',
        name: 'storeUser',
        hidden: true,
        component: () => import('@/views/delivery/storeUser.vue'),
        meta: {
          title: '配送费用',
          icon: 'icon-shouye',
          roles: ['root']
        }
      }
    ]
  },
  {
    path: '/paymentFlow',
    name: 'paymentFlow',
    meta: {
      title: '支付流水',
      icon: 'icon-echarts',
      roles: ['root']
    },
    component: Layout,
    redirect: '/paymentFlow/index',
    children: [
      {
        path: 'index',
        name: 'paymentFlow',
        component: () => import('@/views/paymentFlow/index.vue'),
        meta: {
          title: '支付流水',
          icon: 'icon-shouye',
          roles: ['root']
        }
      },
      {
        path: 'storeFlow',
        name: 'paymentFlowStoreFlow',
        hidden: true,
        component: () => import('@/views/paymentFlow/storeFlow.vue'),
        meta: {
          title: '支付流水',
          icon: 'icon-shouye',
          roles: ['root']
        }
      }
    ]
  },
  {
    path: '/marketingData',
    name: 'marketingData',
    meta: {
      title: '营销数据',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/marketingData/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/marketingData/index.vue'),
        meta: {
          title: '营销数据',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'detail',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/marketingData/detail.vue'),
        meta: {
          title: '营销数据',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/marketingActivities',
    name: 'marketingActivities',
    meta: {
      title: '营销活动',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/marketingActivities/index',
    children: [
      {
        path: 'index',
        name: 'index',
        hidden: true,
        component: () => import('@/views/marketingActivities/index.vue'),
        meta: {
          title: '营销活动',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/salesReport',
    name: 'salesReport',
    meta: {
      title: '销售统计',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/salesReport/list',
    children: [
      {
        path: 'list',
        name: 'list',
        component: () => import('@/views/salesReport/list.vue'),
        meta: {
          title: '销售统计',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'detail',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/salesReport/detail.vue'),
        meta: {
          title: '销售统计',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/paymentFlow',
    name: 'paymentFlow',
    meta: {
      title: '资金流水',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/paymentFlow/storeFlow',
    children: [
      {
        path: 'storeFlow',
        name: 'storeFlow',
        component: () => import('@/views/paymentFlow/storeFlow.vue'),
        meta: {
          title: '资金流水',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/shippingFees',
    name: 'shippingFees',
    meta: {
      title: '配送费用',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/shippingFees/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/shippingFees/index.vue'),
        meta: {
          title: '配送费用',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'detail',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/shippingFees/detail.vue'),
        meta: {
          title: '配送费用',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/monthlyStatementBranch',
    name: 'monthlyStatementBranch',
    meta: {
      title: '月结签单',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/monthlyStatementBranch/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/monthlyStatementBranch/index.vue'),
        meta: {
          title: '月结签单',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'detail',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/monthlyStatementBranch/detail.vue'),
        meta: {
          title: '月结签单',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/businessOverview',
    name: 'businessOverview',
    meta: {
      title: '经营概况',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/businessOverview/store',
    children: [
      {
        path: 'store',
        name: 'store',
        component: () => import('@/views/businessOverview/store.vue'),
        meta: {
          title: '经营概况',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/emptyBucket',
    name: 'emptyBucket',
    meta: {
      title: '桶押金管理',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/emptyBucket/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/emptyBucket/index.vue'),
        meta: {
          title: '桶押金管理',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'detail',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/emptyBucket/detail.vue'),
        meta: {
          title: '桶押金管理',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/commodity',
    name: 'commodity',
    meta: {
      title: '商品管理',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/commodity/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/commodity/index.vue'),
        meta: {
          title: '商品管理',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'brand',
        name: 'brand',
        component: () => import('@/views/brand/index.vue'),
        meta: {
          title: '商品品牌',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'classification',
        name: 'classification',
        component: () => import('@/views/classification/index.vue'),
        meta: {
          title: '商品分类',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '订单管理',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/order/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/order/index.vue'),
        meta: {
          title: '全部订单',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'pendingPayment',
        name: 'pendingPayment',
        component: () => import('@/views/order/pendingPayment.vue'),
        meta: {
          title: '待付款订单',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'invalid',
        name: 'invalid',
        component: () => import('@/views/order/invalid.vue'),
        meta: {
          title: '无效订单',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'pendingOrder',
        name: 'pendingOrder',
        component: () => import('@/views/order/pendingOrder.vue'),
        meta: {
          title: '待接订单',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'pendingDelivery',
        name: 'pendingDelivery',
        component: () => import('@/views/order/pendingDelivery.vue'),
        meta: {
          title: '待配送订单',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'inDelivery',
        name: 'inDelivery',
        component: () => import('@/views/order/inDelivery.vue'),
        meta: {
          title: '配送中订单',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'finish',
        name: 'finish',
        component: () => import('@/views/order/finish.vue'),
        meta: {
          title: '已完成订单',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'detail',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/order/detail.vue'),
        meta: {
          title: '订单详情',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      title: '用户管理',
      icon: 'icon-echarts',
      roles: ['branch', 'front-desk']
    },
    component: Layout,
    redirect: '/user/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/user/index.vue'),
        meta: {
          title: '用户列表',
          icon: 'icon-shouye',
          roles: ['branch', 'front-desk']
        }
      }
      // ,
      // {
      //   path: 'userType',
      //   name: 'userType',
      //   component: () => import('@/views/userType/index.vue'),
      //   meta: {
      //     title: '用户类型',
      //     icon: 'icon-shouye',
      //     roles: ['branch']
      //   }
      // }
    ]
  },
  {
    path: '/role',
    name: 'role',
    meta: {
      title: '角色管理',
      icon: 'icon-echarts',
      roles: ['branch']
    },
    component: Layout,
    redirect: '/role/delivery',
    children: [
      {
        path: 'delivery',
        name: 'delivery',
        component: () => import('@/views/role/delivery.vue'),
        meta: {
          title: '配送人员列表',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      },
      {
        path: 'front-desk',
        name: 'front-desk',
        component: () => import('@/views/role/front-desk.vue'),
        meta: {
          title: '物业前台人员',
          icon: 'icon-shouye',
          roles: ['branch']
        }
      }
    ]
  },
  {
    path: '/placeAnOrder',
    name: 'placeAnOrder',
    meta: {
      title: '下单管理',
      icon: 'icon-echarts',
      roles: ['front-desk']
    },
    component: Layout,
    redirect: '/placeAnOrder/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/placeAnOrder/index.vue'),
        meta: {
          title: '下单管理',
          icon: 'icon-shouye',
          roles: ['front-desk']
        }
      }
    ]
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    meta: {
      title: '库存管理',
      icon: 'icon-echarts',
      roles: ['front-desk', 'branch']
    },
    component: Layout,
    redirect: '/warehouse/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/warehouse/index.vue'),
        meta: {
          title: '库存管理',
          icon: 'icon-shouye',
          roles: ['front-desk', 'branch']
        }
      },
      {
        path: 'detail',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/warehouse/detail.vue'),
        meta: {
          title: '库存管理',
          icon: 'icon-shouye',
          roles: ['front-desk', 'branch']
        }
      },
      {
        path: 'print',
        name: 'print',
        hidden: true,
        component: () => import('@/views/warehouse/print.vue'),
        meta: {
          title: '库存管理',
          icon: 'icon-shouye',
          roles: ['front-desk', 'branch']
        }
      }
    ]
  },
  {
    path: '/onTheWay',
    name: 'onTheWay',
    meta: {
      title: '在途空桶管理',
      icon: 'icon-echarts',
      roles: ['front-desk']
    },
    component: Layout,
    redirect: '/onTheWay/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/onTheWay/index.vue'),
        meta: {
          title: '在途空桶管理',
          icon: 'icon-shouye',
          roles: ['front-desk']
        }
      },
      {
        path: 'detail',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/onTheWay/detail.vue'),
        meta: {
          title: '在途空桶管理',
          icon: 'icon-shouye',
          roles: ['front-desk']
        }
      },
      {
        path: 'detail-commodity',
        name: 'detail-commodity',
        hidden: true,
        component: () => import('@/views/onTheWay/detail-commodity.vue'),
        meta: {
          title: '在途空桶管理',
          icon: 'icon-shouye',
          roles: ['front-desk']
        }
      }
    ]
  },
  {
    path: '/distribution',
    name: 'distribution',
    meta: {
      title: '配货出库',
      icon: 'icon-echarts',
      roles: ['front-desk']
    },
    component: Layout,
    redirect: '/distribution/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/distribution/index.vue'),
        meta: {
          title: '配货出库',
          icon: 'icon-shouye',
          roles: ['front-desk']
        }
      }
    ]
  },
  {
    path: '/recycle',
    name: 'recycle',
    meta: {
      title: '回桶作业',
      icon: 'icon-echarts',
      roles: ['front-desk']
    },
    component: Layout,
    redirect: '/recycle/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/recycle/index.vue'),
        meta: {
          title: '回桶作业',
          icon: 'icon-shouye',
          roles: ['front-desk']
        }
      }
    ]
  }
]
//
// const router = new VueRouter({
//   base: '/',
//   isAddAsyncMenuData: false,
//   mode: 'hash',
//   constantRouterMap
// });
// // const createRouter = () => {
// //   return new VueRouter({
// //     base: '/',
// //     mode: 'history',
// //     routes: routes
// //   });
// // };
// // const route = createRouter();
//
// router.beforeEach((to, from, next) => {
//   to.query.studyId = 123123;
//   console.log(to);
//   NProgress.start();
//   next();
// });
//
// router.afterEach((to, from) => {
//   console.log(to);
//   NProgress.done();
// });
// export default router;

const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router