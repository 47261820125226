/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 14:05:10
 * @LastEditTime: 2021-12-29 14:05:10
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  activeValue: 1,
  inactiveValue: 2,
  activeColor: '#FDC331',
  inActiveColor: '#E2E4E9',
  activeMessage: '是否启用？',
  inActiveMessage: '是否停用？',
  openMessageBox: false,
  params: {},
  method: 'post',
  field: 'status',
  isLoading: true,
}