/*
 * @Description: 
 * @Author: Devin
 * @Date: 2021-12-29 14:24:06
 * @LastEditTime: 2021-12-29 14:24:06
 * @LastEditors: Devin
 * @Reference: 
 */
export const config = {
  list: [],
  height: 50,
  itemHeight: 50,
  setStartIndex: 0
}