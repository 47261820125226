import router from '@/router'
import { getToken, getRoles } from '@/utils/auth'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style


NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title

  // determine whether the user has logged in
  const hasToken = getToken()
  console.log(hasToken)
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      // const hasRoles = getRoles();
      if (hasRoles) {
        console.log('roles: ' + hasRoles)
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { roles } = await store.dispatch('user/getInfo')
          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
          // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          // await store.dispatch('user/resetToken')
          // Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})


//路由判断登录，根据路由配置文件的参数
// router.beforeEach(async(to,from,next)=>{
//   //判断该路由是否需要登录权限
//   //record.meta.requireAuth是获取到该请求中携带的该参数
//   console.log(to)
//   console.log(!to.meta.requireAuth)
//   // if (!to.meta.requireAuth){
//     //获取到本地的token
//     const token = getToken()
//     console.log("显示token----------：" + token)
//
//     //判断当前的token是否存在，也就是登录时的token
//     if (token){
//       //如果指向的是登录页面，不做任何操作
//       if (to.path === "/login"){
//
//       }else {
//         //如果不是登录页面，且token存在，就放行
//         const accessRoutes = await store.dispatch('permission/generateRoutes', 'root')
//         console.log(accessRoutes)
//         router.addRoutes(accessRoutes) // 动态添加可访问路由表
//         next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
//         // next()
//       }
//     }else {
//       //    如果token不存在
//       //    前往登录
//       console.log('前往登录')
//       next({path:'/login'})
//     }
//
//   // }else {
//   //   //如果不需要登录认证，就直接访问
//   //   next()
//   // }
// })